import { gql, useQuery } from '@apollo/client';

import { UsersResponseItem } from './usersByOrganizationId';

export interface IConnectedToAccount {
  id: string;
  isActive: boolean;
  numberAccounts: number;
  list: string[];
  picture?: string;
  name?: string;
  info?: string;
}
export interface UserProfile extends UsersResponseItem {
  isConnectedToGoogle: IConnectedToAccount;
  isConnectedToFacebook: IConnectedToAccount;
  isConnectedToTikTok: IConnectedToAccount;
  isConnectedToTikTokCreator: IConnectedToAccount;
}

export interface UserProfileResponse {
  myInfo: UserProfile;
}

export const USER_PROFILE = gql`
  query myInfo {
    myInfo {
      id
      username
      organizationId
      receiveEmailNotifications
      mailConfirmed
      lastActiveAt
      phoneNumber
      marketplaceLicense {
        token
        template
        footer
      }
      isConnectedToGoogle {
        id
        isActive
        numberAccounts
        list
        picture
        info
        name
      }
      isConnectedToTikTok {
        id
        isActive
        numberAccounts
        list
        picture
        info
        name
      }
      isConnectedToTikTokCreator {
        id
        isActive
        numberAccounts
        list
        picture
        info
        name
      }
      isConnectedToFacebook {
        id
        isActive
        picture
        name
        info
        numberAccounts
        list
      }
      fullName
      title
      avatar {
        id
        signedUrl
      }
      role
      products
    }
  }
`;

export function useMyProfileQuery() {
  return useQuery<UserProfileResponse>(USER_PROFILE, { fetchPolicy: 'cache-and-network' });
}
