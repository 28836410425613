import React, { useContext, useRef, useState } from 'react';

import { useMutation } from '@apollo/client';

import { UserProfileLayout } from '../../components/Layout/UserProfileLayout';
import AccountInformationCard from '../../components/ui/AccountInformationCard';
import AvatarImage from '../../components/ui/AvatarImage';
import { ChangePasswordForm, ChangePasswordFormValue } from '../../components/ui/Forms/ChangePassword';
import { ModalWithFooter } from '../../components/ui/ModalWithFooter';
import { snackbar } from '../../components/ui/Snackbar';
import { SideDrawerContext } from '../../context/SideDrawerProvider';
import { CHANGE_PASSWORD } from '../../graphql/mutations/changePassword';
import { useMyProfileQuery } from '../../graphql/query/userProfile';
import { FormRef } from '../../models/form';
import { Spacer } from '../../components/ui/Spacer';
import { Button } from 'antd';

export const UserProfile: React.FC = () => {
  const sideDrawerContext = useContext(SideDrawerContext);
  const editAccountHandler = () =>
    sideDrawerContext.toggleSideDrawer(sideDrawerContext.sideDrawerNames.editUserAccount, data);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const { data } = useMyProfileQuery();
  const formRef = useRef<FormRef<ChangePasswordFormValue>>(null);
  const [changePassword] = useMutation(CHANGE_PASSWORD);

  const handleChangePassword = async (formData: ChangePasswordFormValue) => {
    try {
      const response = await changePassword({
        variables: { data: formData },
      });
      setShowPasswordModal(false);
      const errorMessage = response.data?.changePassword?.errorMessage;
      if (errorMessage) {
        snackbar.error({
          message: errorMessage,
        });
      } else
        snackbar.success({
          message: 'Password successfully changed',
        });
    } catch (error: any) {
      snackbar.error({ message: error?.message });
    }
  };
  return (
    <UserProfileLayout
      leftHeaderAvatar={
        <AvatarImage size={'extraLarge'} nameAvatar={data?.myInfo?.fullName} src={data?.myInfo?.avatar?.signedUrl} />
      }
      userName={data?.myInfo?.fullName}
      position={data?.myInfo?.title}
      userInfo={data?.myInfo}
      leftContent={
        <>
          <AccountInformationCard
            phone={data?.myInfo.phoneNumber || 'unkwnown phone number'}
            email={data?.myInfo.username || 'unknown'}
            marketplaceToken={data?.myInfo.marketplaceLicense?.token}
            emailNotifications={data?.myInfo.receiveEmailNotifications === true}
            emailNotificationsChange={() => {}}
            topIconButton={{ icon: 'edit', onClick: editAccountHandler }}
          ></AccountInformationCard>
          <Spacer type="stack" size="m" />
          <div>
            <Button type="primary" onClick={() => setShowPasswordModal(true)}>
              CHANGE PASSWORD
            </Button>
          </div>
          <ModalWithFooter
            visible={showPasswordModal}
            onCancel={() => setShowPasswordModal(false)}
            onOk={() => formRef.current?.submit()}
            title={'Change Password'}
            okText={'CHANGE PASSWORD'}
          >
            <ChangePasswordForm ref={formRef} onCompleted={handleChangePassword} />
          </ModalWithFooter>
        </>
      }
    />
  );
};
