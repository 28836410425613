import { gql, useQuery } from '@apollo/client';

export enum PartnerType {
  LocalShiftOwnership = 'LocalShiftOwnership',
  ResellerOwned = 'ResellerOwned',
  ResellerSupport = 'ResellerSupport',
  Referral = 'Referral',
}

export interface Partner {
  id: string;
  name: string;
  isActive: boolean;
  type?: PartnerType;
}

export interface PartnersResponse {
  partners: Partner[];
}

export interface PartnersRequest {
  onlyActive?: boolean;
  name?: string;
}

export const PartnerFieldsFragment = gql`
  fragment PartnerFields on Partner {
    id
    name
    isActive
    type
  }
`;

export const PARTNER_QUERY = gql`
  query partners($onlyActive: Boolean, $name: String) {
    partners(name: $name, onlyActive: $onlyActive) {
      ...PartnerFields
    }
  }
  ${PartnerFieldsFragment}
`;

export function usePartnerQuery(onlyActive = true) {
  const { data, error, loading, refetch } = useQuery<PartnersResponse>(PARTNER_QUERY, {
    variables: { onlyActive },
  });

  return {
    partners: data?.partners || [],
    error,
    loading,
    refetch,
  };
}
