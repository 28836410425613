import React, { useEffect, useState } from 'react';

import { Collapse } from 'antd';

import { DownOutlined } from '@ant-design/icons';

import styled from 'styled-components';

const { Panel } = Collapse;

export const CollapseWrapper = styled.div`
  border: 1px solid #f4f3f6;
  border-radius: 4px;

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 0px;
    right: 4px;
  }

  .ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding-top: 0px;
  }

  .ant-collapse-content {
    text-align: left;
    padding: 16px;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px;
    padding-top: 12px;
  }

  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    background: #f9fafb;
    padding: 16px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 21px;
    right: 16px;
  }
`;

interface CollapseOptionProps {
  active?: boolean;
  header: React.ReactNode;
}

export const CollapseOption: React.FC<CollapseOptionProps> = ({ active = true, children, header }) => {
  const [open, setOpen] = useState<string[]>([]);

  const onChange = () => {
    if (active) {
      setOpen((prev) => (prev?.length ? [] : ['panel']));
    }
  };
  useEffect(() => {
    if (!active) {
      setOpen(() => []);
    }
  }, [active]);

  return (
    <CollapseWrapper>
      <Collapse
        ghost
        expandIconPosition="right"
        activeKey={open}
        expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        onChange={onChange}
      >
        <Panel disabled={!active} forceRender={active} header={header} key="panel">
          {children}
        </Panel>
      </Collapse>
    </CollapseWrapper>
  );
};
