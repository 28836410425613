import { gql, useQuery } from '@apollo/client';

import { LocationItem } from './locationsByOrgId';

console.log('');

export interface LocationByOrgIdsResponse {
  locationsByOrgIds: LocationItem[];
}
export interface LocationByOrgIdsParams {
  organizationIds: string[];
  name?: string;
}

export const LOCATIONS_BY_ORG_IDS = gql`
  query ($organizationIds: [String!]!, $name: String) {
    locationsByOrgIds(organizationIds: $organizationIds, name: $name) {
      id
      name
    }
  }
`;
export function useLocationByOrgIdsQuery(organizationIds: string[], name?: string) {
  const { data, refetch } = useQuery<LocationByOrgIdsResponse, LocationByOrgIdsParams>(LOCATIONS_BY_ORG_IDS, {
    variables: {
      organizationIds: organizationIds,
      name: name,
    },
  });
  return {
    refetch,
    locations: data?.locationsByOrgIds || [],
  };
}
