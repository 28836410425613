import React, { ReactNode } from 'react';

import { Drawer } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import styled from 'styled-components';

import mainTheme from '../../StyleGuide/themes/mainTheme';
import { Heading4 } from '../Headings/Headings.styled';
import { Button } from '../Buttons/Button';

const SideDrawerContainer = styled.main`
  height: 100%;
  display: flex;
`;

const SideDrawerButtonsContainer = styled.footer`
  display: flex;
  justify-content: flex-end;
  padding: 6px 14px;
  align-items: center;
  min-width: 440px;
`;
const ButtonContainer = styled.div`
  margin-left: 16px;
`;

const HeadingStyled = styled(Heading4)`
  color: ${(props) => props.theme.colors.dimGray.base};
`;

interface SideDrawerProps extends DrawerProps {
  heading: string;
  isDrawerVisible: boolean;
  toggleDrawerVisible: (value?: boolean) => void;
  ctaButtons: {
    label: string;
    onClick: () => void;
    isDisabled: boolean;
  }[];
  children: ReactNode;
}

const SideDrawer: React.FC<SideDrawerProps> = ({
  heading,
  isDrawerVisible,
  toggleDrawerVisible,
  ctaButtons,
  children,
  ...props
}) => {
  const onClose = () => {
    toggleDrawerVisible(false);
  };

  return (
    <>
      <Drawer
        title={<HeadingStyled>{heading}</HeadingStyled>}
        headerStyle={{
          padding: '16px',
          fontFamily: mainTheme.fonts.header,
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '20px',
          lineHeight: '24px',
          color: '#3A3A41',
          height: '56px',
        }}
        placement="right"
        onClose={onClose}
        visible={isDrawerVisible}
        width="auto"
        closeIcon={<i className="icon misc-close" />}
        bodyStyle={{ padding: 0 }}
        contentWrapperStyle={{
          maxWidth: '35%',
        }}
        {...props}
        footer={
          <SideDrawerButtonsContainer>
            {ctaButtons.length === 2 && (
              <Button btnType="secondary" disabled={ctaButtons[1].isDisabled} onClick={() => ctaButtons[1].onClick()}>
                {ctaButtons[1].label}
              </Button>
            )}
            <ButtonContainer>
              <Button btnType="primary" disabled={ctaButtons[0].isDisabled} onClick={() => ctaButtons[0].onClick()}>
                {ctaButtons[0].label}
              </Button>
            </ButtonContainer>
          </SideDrawerButtonsContainer>
        }
      >
        <SideDrawerContainer>{children}</SideDrawerContainer>
      </Drawer>
    </>
  );
};

export default SideDrawer;
