import React from 'react';
import styled from 'styled-components';
import SimpleCard from '../SimpleCard';
import { LabelBodyB, LabelBodyR } from '../Text/Text.styled';

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 16px;
`;
const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 0;
`;
const LabelBodyBStyled = styled(LabelBodyB)`
  color: ${(props) => props.theme.colors.dimGray.minus1};
`;
const LabelBodyRStyled = styled(LabelBodyR)`
  color: ${(props) => props.theme.colors.dimGray.base};
`;

export const SimpleCardStyled = styled(SimpleCard)`
  margin: 0 0 32px 1;
  border-radius: 8px;
`;

export interface AccountInformationCardProps {
  phone: string;
  email: string;
  marketplaceToken?: string;
  emailNotifications: boolean;
  emailNotificationsChange: () => void;
  topIconButton?: {
    icon: 'edit' | 'plus';
    onClick: () => void;
  };
}

const AccountInformationCard: React.FC<AccountInformationCardProps> = ({
  marketplaceToken,
  phone,
  email,
  topIconButton,
}) => {
  return (
    <SimpleCardStyled style={{ width: '100%' }} topHeader="Account Information" topIconButton={topIconButton}>
      <CardContainer>
        <RowContainer>
          <LabelBodyBStyled>Phone</LabelBodyBStyled>
          <LabelBodyRStyled>{phone}</LabelBodyRStyled>
        </RowContainer>
        <RowContainer>
          <LabelBodyBStyled>Email</LabelBodyBStyled>
          <LabelBodyRStyled>{email}</LabelBodyRStyled>
        </RowContainer>
        {marketplaceToken && (
          <RowContainer>
            <LabelBodyBStyled>Marketplace Token</LabelBodyBStyled>
            <LabelBodyRStyled>{marketplaceToken}</LabelBodyRStyled>
          </RowContainer>
        )}
      </CardContainer>
    </SimpleCardStyled>
  );
};

export default AccountInformationCard;
