import axios from 'axios';

export interface LoginRequest {
  username: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
}
export interface ValidateEmailResponse {
  email: string;
}

export interface ForgotPassRequest {
  username: string;
}

export interface ResetPassRequest {
  id: string;
  password: string;
  confirmPassword: string;
}

export const loginRequest = async (data: LoginRequest): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(`/api/auth/login`, data, { withCredentials: true });
  return response.data;
};

export const mockRequest = async (userId: string): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(
    `/api/auth/mockUser`,
    { mockUserId: userId },
    { withCredentials: true }
  );
  return response.data;
};

export const logoutRequest = async (): Promise<LoginResponse> => {
  const response = await axios.post<LoginResponse>(`/api/auth/logout`, {}, { withCredentials: true });
  return response.data;
};

export const validateEmail = async (data: ValidateEmailResponse) => {
  const response = await axios.post<boolean>(`/api/organization/validate-email`, data, {
    withCredentials: true,
  });
  return response.data;
};
export const forgotPasswordRequest = async (data: ForgotPassRequest) => {
  const response = await axios.post('/api/auth/reset-password', data, { withCredentials: false });
  return response;
};

export const passwordResetRequest = async (data: ResetPassRequest) => {
  const response = await axios.put('/api/auth/reset-password', data, { withCredentials: false });
  return response;
};
