import React, { useEffect, useState } from 'react';

import { Tooltip } from 'antd';

import styled from 'styled-components';

import { Metric, PostMetricLabel, useGetPostAggregates } from '../../../../graphql/query/getPostAggregates';
import { DashboardFilter } from '../../../../pages/Dashboard/hooks/useFilters';
import { intToString } from '../../../utils/convert.utils';
import { Button } from '../../Buttons/Button';
import IconButtonSimple from '../../IconButtonSimple';
import { icons } from '../../IconButtonSimple/IconButtonSimple';
import { Spacer } from '../../Spacer';
import { H3, LabelB, LabelBoldSmall, LabelR } from '../../Text/Text.styled';
import { SocialAccount } from '../../VehicleColumn/modeViews/VehicleSocialAccounts';
import { CardStyled } from './Components.styles';

interface CardProperties {
  disabled?: boolean;
}
const Card = styled(CardStyled)<CardProperties>`
  padding: 16px;
  width: 20%;
  height: 136px;
  background-color: ${(props) => (props.disabled ? props.theme.colors.dimGray.plus2 : props.theme.colors.white)};
  .ant-card-body {
    padding: 0px;
  }
`;
const SummaryPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

const LowerSection = styled.div<{ forbidden?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: ${(props) => (props.forbidden ? '8px' : '16px')};
  justify-content: ${(props) => (props.forbidden ? 'center' : 'space-between')};
`;

const AccountIcon = styled.div`
  height: 24px;
  width: 24px;
`;

const UpperSection = styled.div<{ forbidden?: boolean }>`
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #f4f3f6;
  display: flex;
  justify-content: ${(props) => (props.forbidden ? 'center' : 'space-between')};
  align-items: center;
`;

const ProductName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const MetricSection = styled.div`
  display: flex;
  flex-direction: row;
`;
const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 4px 0px 4px 12px;
  gap: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
`;

const LearnMoreButton = styled(Button)`
  margin-bottom: 12px;
  align-self: center;
`;
interface SummaryPostProps {
  platform: SocialAccount;
  dashboardFilters: DashboardFilter;
  forbidden?: boolean;
}

interface IconState {
  disabled: string;
  normal: string;
}
const productLabel: Record<SocialAccount, string> = {
  facebook: 'Facebook',
  google: 'Google',
  facebook_marketplace: 'Marketplace',
  instagram: 'Instagram',
  tik_tok: 'TikTok',
};
const platformIcons: Record<SocialAccount, IconState> = {
  facebook: {
    normal: 'ic ic_facebook_filled',
    disabled: 'ic ic_facebook_grey',
  },
  instagram: {
    normal: 'ic ic_ig_filled',
    disabled: 'ic ic_ig_grey',
  },
  google: {
    normal: 'ic ic_google_filled',
    disabled: 'ic ic_google_grey',
  },
  facebook_marketplace: {
    normal: 'ic ic_fb_marketplace_filled',
    disabled: 'ic ic_fb_marketplace_grey',
  },
  tik_tok: {
    normal: 'ic ic_tiktok_filled',
    disabled: 'ic ic_tiktok_grey',
  },
};

const metricIcon: Record<PostMetricLabel, keyof typeof icons> = {
  aggViewsPerPost: 'view',
  aggClickPerPost: 'click',
  aggMessagePerPost: 'email',
  aggCallPerPost: 'phone',
};

const metricLabel: Record<PostMetricLabel, string> = {
  aggViewsPerPost: 'Views',
  aggClickPerPost: 'Clicks',
  aggMessagePerPost: 'Messages',
  aggCallPerPost: 'Calls',
};

const ProhibitedProduct: React.FC<{ platform: SocialAccount }> = ({ platform }) => {
  const handleClick = () => {
    const webChat = document.getElementById('podium-bubble') as any;
    const webChatDoc = webChat?.contentDocument;
    const element = webChatDoc?.getElementsByClassName('frame-content')[0];
    if (element) {
      element.style.display = 'none';
    }
    webChatDoc?.getElementsByClassName('ContactBubble__Icon')[0].click();
  };
  return (
    <SummaryPostWrapper>
      <UpperSection forbidden>
        <ProductName>
          <AccountIcon className={platformIcons[platform]['disabled']} />
          <LabelB>{productLabel[platform]}</LabelB>
        </ProductName>
      </UpperSection>
      <LowerSection forbidden>
        <LearnMoreButton
          btnType="secondary"
          size="small"
          onClick={() => {
            handleClick();
          }}
        >
          Learn More
        </LearnMoreButton>
      </LowerSection>
    </SummaryPostWrapper>
  );
};

export const SummaryPost: React.FC<SummaryPostProps> = ({ platform, dashboardFilters, forbidden = false }) => {
  const [totalPosts, setTotalPosts] = useState<number>(0);
  const [metrics, setMetrics] = useState<Metric[]>([]);
  const { postsAgg, loading } = useGetPostAggregates({
    getPostAggregateRequest: {
      organizationIds: dashboardFilters.organizationIds,
      startDate: dashboardFilters.dateRange.startDate,
      endDate: dashboardFilters.dateRange.endDate,
      platforms: [platform],
      locationIds: dashboardFilters.locationIds,
      postTypes: dashboardFilters.postTypes,
    },
  });

  useEffect(() => {
    if (postsAgg) {
      setMetrics(postsAgg?.metrics ?? []);
      setTotalPosts(postsAgg?.totalPosts);
    }
  }, [postsAgg]);
  return (
    <Card disabled={forbidden}>
      {forbidden ? (
        <ProhibitedProduct platform={platform} />
      ) : (
        <SummaryPostWrapper>
          <UpperSection>
            <ProductName>
              <AccountIcon className={platformIcons[platform][forbidden ? 'disabled' : 'normal']} />
              <LabelB>{productLabel[platform]}</LabelB>
            </ProductName>
            <InfoCard>
              <div>
                <H3>{loading ? 0 : intToString(totalPosts)}</H3>
                <LabelR>Posts</LabelR>
              </div>
            </InfoCard>
          </UpperSection>
          <LowerSection>
            {metrics.map((metric) => {
              return (
                <Tooltip placement="bottom" title={`Total ${metricLabel[metric.label]}`}>
                  <MetricSection>
                    <IconButtonSimple
                      icon={metricIcon[metric.label]}
                      size={'small'}
                      //@ts-ignore
                      css={'margin-right: 100px'}
                    />
                    <Spacer type="inline" size="xxxs"></Spacer>
                    <LabelBoldSmall>{loading ? 0 : intToString(metric.value)}</LabelBoldSmall>
                  </MetricSection>
                </Tooltip>
              );
            })}
          </LowerSection>
        </SummaryPostWrapper>
      )}
    </Card>
  );
};
