import React, { useEffect, useState } from 'react';
import { Select, Input, Button } from 'antd';
import { SimpleCardStyled } from './AccountInformationCard';
import { Spacer } from '../Spacer';
import { LabelBodyB } from '../Text/Text.styled';
import { UserProfile } from '../../../graphql/query/userProfile';
import { useMutation } from '@apollo/client';
import { SAVE_MARKETPLACE_TEMPLATE } from '../../../graphql/mutations/saveMarketplaceUserTemplate';
import { snackbar } from '../Snackbar';
import * as Sentry from '@sentry/react';
import { displayGqlErrors } from '../ErrorList';

const { TextArea } = Input;

const DEFAULT_MARKETPLACE_VARIABLES = [
  'vin',
  'stock',
  'body',
  'trim',
  'make',
  'model',
  'modelNumber',
  'engine',
  'driveType',
  'transmission',
  'extColor',
  'intColor',
];

const variableDisplayMap: Record<string, string> = {
  vin: 'VIN: @vin',
  stock: 'Stock: @stock',
  condition: 'Condition: @condition',
  price: 'Price: @price',
  model: 'Model: @model',
  body: 'Body: @body',
  trim: 'Trim: @trim',
  transmission: 'Transmission: @transmission',
  driveType: 'Drive Type: @driveType',
  intColor: 'Interior Color: @intColor',
  extColor: 'Exterior Color: @extColor',
  engine: 'Engine: @engine',
  engineHours: 'Engine Hours: @engineHours',
  year: 'Year: @year',
  mileage: 'Mileage: @mileage',
  make: 'Make: @make',
  modelNumber: 'Model Number: @modelNumber',
  address: 'Address: @address',
  dealershipNumber: 'Dealership Number: @dealershipNumber',
  locationName: 'Location Name: @locationName',
  vehDescription: 'Description: @vehDescription',
};

export const defaultMarketplaceTemplate = () => {
  return DEFAULT_MARKETPLACE_VARIABLES.map((variable) => variableDisplayMap[variable]).join('\n');
};

const PostDescriptionBuilder: React.FC<{ user?: UserProfile }> = ({ user }) => {
  const parseTemplateVariables = (template: string): string[] => {
    const matches = template.match(/@(\w+)/g);
    return matches ? matches.map((match) => match.slice(1)) : [];
  };

  const variableValues: Record<string, string> = {
    vin: '1HGCM82633A123456',
    stock: 'A1234',
    condition: 'New',
    price: '$20,000',
    model: 'Accord',
    body: 'Sedan',
    trim: 'EX',
    transmission: 'Automatic',
    driveType: 'FWD',
    intColor: 'Black',
    extColor: 'White',
    engine: '2.0L I4',
    engineHours: '120',
    year: '2023',
    mileage: '0',
    make: 'Honda',
    modelNumber: 'AC123',
    address: '123 Main St, Springfield',
    dealershipNumber: '12345',
    locationName: 'Springfield Honda',
    vehDescription: 'This is a new Honda Accord. It has a 2.0L I4 engine and 120 engine hours.',
  };

  const [selectedVariables, setSelectedVariables] = useState<string[]>(DEFAULT_MARKETPLACE_VARIABLES);
  const [footer, setFooter] = useState<string>('');
  const [template, setTemplate] = useState<string>(defaultMarketplaceTemplate());

  useEffect(() => {
    const fetchedTemplate = user?.marketplaceLicense?.template;
    const fetchedFooter = user?.marketplaceLicense?.footer;

    setFooter(fetchedFooter || '');

    if (fetchedTemplate) {
      setTemplate(fetchedTemplate);
      setSelectedVariables(parseTemplateVariables(fetchedTemplate));
    } else {
      const defaultTemplate = DEFAULT_MARKETPLACE_VARIABLES.map((variable) => variableDisplayMap[variable]).join('\n');
      setTemplate(defaultTemplate);
      setSelectedVariables(DEFAULT_MARKETPLACE_VARIABLES);
    }
  }, [user?.marketplaceLicense?.template, user?.marketplaceLicense?.footer]);

  const [saveTemplate] = useMutation(SAVE_MARKETPLACE_TEMPLATE);

  const handleVariableChange = (value: string[]) => {
    if (!value.includes('vin')) {
      value.unshift('vin');
      snackbar.warning({ message: 'VIN is required in the template' });
    }

    setSelectedVariables(value);
    const variableTemplate = value.map((variable) => variableDisplayMap[variable]).join('\n');
    setTemplate(variableTemplate);
  };

  const handleFooterChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFooter(e.target.value);
  };

  const getPreview = () => {
    let preview = template;
    selectedVariables.forEach((variable) => {
      const regex = new RegExp(`@${variable}`, 'g');
      preview = preview.replace(regex, variableValues[variable] || `(${variable})`);
    });
    return `${preview}\n\n${footer}`.trim();
  };

  const handleSave = async () => {
    try {
      const response = await saveTemplate({
        variables: { userId: user?.id, template, footer },
      });

      if (response.data.saveMarketplaceTemplate) {
        snackbar.success({
          message: 'Template saved successfully',
        });
      } else {
        snackbar.error({
          message: 'Failed to save template',
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error, `Unable to save template.`);
    }
  };

  if (!user?.marketplaceLicense) return null;

  return (
    <SimpleCardStyled style={{ width: '100%' }} topHeader="Marketplace Post Template">
      <div style={{ padding: '24px', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
        {/* Left Section */}
        <div style={{ width: '48%' }}>
          <LabelBodyB>Select Variables</LabelBodyB>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select variables"
            value={selectedVariables}
            onChange={handleVariableChange}
            options={Object.keys(variableDisplayMap).map((key) => ({
              label: key,
              value: key,
            }))}
          />
          <Spacer type="stack" size="s" />
          <LabelBodyB>Footer (Free Text)</LabelBodyB>
          <TextArea rows={4} placeholder="Enter footer text here" value={footer} onChange={handleFooterChange} />
        </div>

        {/* Right Section */}
        <div style={{ width: '48%' }}>
          <LabelBodyB>Preview</LabelBodyB>
          <div
            style={{
              border: '1px solid #d9d9d9',
              padding: '8px',
              whiteSpace: 'pre-wrap',
              height: '90%',
              textAlign: 'left',
              wordWrap: 'break-word',
            }}
          >
            {getPreview() || 'Preview will appear here...'}
          </div>
        </div>
      </div>
      <Button type="primary" style={{ marginTop: '16px' }} onClick={handleSave}>
        SAVE DESCRIPTION{' '}
      </Button>
      <Spacer type="stack" size="m" />
    </SimpleCardStyled>
  );
};

export default PostDescriptionBuilder;
