import React from 'react';
import styled from 'styled-components';
import { LabelBodyB } from '../../ui/Text/Text.styled';
import ExtensionBanner from '../../ui/ExtensionBanner/ExtensionBanner';
import { copyTextToClipboard } from '../../../utils/clipboard.utils';
import { CopyOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';

const CHROME_EXTENSION_LINK = 'https://chromewebstore.google.com/detail/localshift/jboaoddjmknbcbdiomphdecaebmfcfli';

const DashboardWrapper = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.header`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors.dimGray.plus3};
  display: flex;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: space-between;
  padding: 16px;
`;

const BodyContainer = styled.section`
  height: 100%;
  display: flex;
  background-color: #f9fafb;
  flex-direction: column;
  padding: 40px;
`;

export interface DashboardLayoutProps {
  mainContent?: JSX.Element;
  menu?: JSX.Element;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ mainContent, menu }) => {
  return (
    <DashboardWrapper>
      <HeaderContainer>
        <LabelBodyB>Dashboard</LabelBodyB>
        <Tooltip title="Click to copy the link">
          <Button
            style={{ borderRadius: '5px' }} // Add rounded corners here
            icon={<CopyOutlined />}
            onClick={() => {
              copyTextToClipboard(CHROME_EXTENSION_LINK);
              message.info('Location ID copied to clipboard');
            }}
            type="primary"
          >
            Extension Install Link
          </Button>
        </Tooltip>
      </HeaderContainer>
      <ExtensionBanner />
      <BodyContainer>
        {menu}
        {mainContent}
      </BodyContainer>
    </DashboardWrapper>
  );
};

export default DashboardLayout;
