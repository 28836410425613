import React from 'react';
import styled from 'styled-components';
import AvatarImage from '../AvatarImage';
import { LabelBold } from '../Text/Text.styled';
import { MarketplaceStats } from '../../../graphql/query/locationsByOrgId';

const PersonInfoCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const TextContainer = styled.div`
  flex-direction: column;
  line-break: after;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`;

const LabelBoldStyled = styled(LabelBold)`
  color: ${(props) => props.theme.colors.dimGray.minus2};
`;

export interface AccountInfoCardProps {
  accountName: string;
  detail: string;
  avatar?: string;
  size?: 'extraSmall' | 'small' | 'medium' | 'large' | 'extraLarge';
  active?: boolean;
  title?: string;
  marketplaceStats?: MarketplaceStats;
}

const AccountInfoCard: React.FC<AccountInfoCardProps> = ({
  marketplaceStats,
  accountName,
  detail,
  avatar,
  size,
  active,
  title,
}) => (
  <PersonInfoCardWrapper>
    <AvatarImage size={size} src={avatar} active={active} nameAvatar={accountName} />
    <TextContainer>
      <LabelBoldStyled>{accountName}</LabelBoldStyled>
      <LabelBoldStyled>{detail}</LabelBoldStyled>
      {title && <LabelBoldStyled>{title}</LabelBoldStyled>}
      {marketplaceStats && (
        <>
          <br></br>
          <LabelBoldStyled>Eligible to Post: {marketplaceStats.totalAvailableToPost}</LabelBoldStyled>
        </>
      )}
    </TextContainer>
  </PersonInfoCardWrapper>
);

AccountInfoCard.defaultProps = {
  size: 'small',
};

export default AccountInfoCard;
